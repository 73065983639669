.AppointmentLoadedContainer {
  padding: 10px 10px 10px;
}
.AppointmentSummaryContainer {
  background: white;
  border-radius: 25px 25px 0 0;
}

.AppointmentSummaryHeader {
  height: 55px;
  padding: 18px 15px 7.5px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #1d5ab9;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
}

.AppointmentSummaryHeader img {
  height: 25px;
  position: absolute;
  right: 20px;
}

.WeAreReadyText {
  height: 27px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7dc033;
  padding: 5px;
}

.SubText {
  margin-top: 12px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #171717;
}

.SummaryTable,
.LotsSummaryTable {
  padding: 0 20px;
  margin-bottom: 20px;
}

.LotsSummaryTable {
  height: 30%;
  overflow: auto;
}

.SummaryTableHeader,
.LotsSummaryTableHeader {
  background: #1d5ab9 !important;
  height: 20px;
  margin: 1px 152px 3px 0;
  font-size: 14px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: white !important;
}

.SummaryTableHeader i,
.LotsSummaryTableHeader i {
  position: absolute;
  right: 28px;
}

.SummaryTableCell {
  padding: 5px 10px !important;
}

.LotTableHeader {
  padding: 5px 10px !important;
}

.LotsSummaryTableFieldsHeading {
  background-color: #f1f1f1;
}

.SummaryTableFieldValue,
.Reasons {
  height: 16px;
  margin: 0 170px 10px 89px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.SummaryTableFieldName,
.LotNumber {
  width: 25%;
  height: 16px;
  margin: 0 89px 10px 12px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.SummaryHeader {
  font-size: 22px;
  text-align: center;
  font-weight: 300;
  padding: 5px;
  color: #171717;
}

.SummaryText {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: 5px;
  color: #171717;
}

.AppointmentScheduledHelpText {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: 5px;
  color: #7dc033;
}

.DateAndTime {
  text-align: center;
  color: #4c83e4;
  margin: 20px 0;
}

.DateStyle {
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: bold;
}

.TimeStyle {
  font-size: 21px;
  font-weight: 600;
}

.LastUpdateTime {
  display: flex;
  font-size: 11px;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.LastUpdateTimeText {
  padding-right: 5px;
}

.LastUpdatedButton {
  padding-left: 5px;
}

.Placeholder {
  width: 90%;
  margin: 10px auto;
  min-height: 500px;
}

.field-section {
  padding-top: 5px;
}

.AppointmentSummaryForm {
  margin: 20px;
}

.AppointmentBadge {
  background-color: #1d5ab9;
  padding: 20px 40px;
}

.AppointmentBadge .header {
  height: 48px;
  margin: 0 9px 14px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  color: #ffffff;
}

.AppointmentBadge .AppointmentBookingText {
  display: flex;
  flex-direction: row;
  color: white;
}

.AppointmentBadge .AppointmentBookingText .subText > div {
  height: 24px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.AppointmentBadge .AppointmentBookingText .phoneIcon {
  width: 63px;
  height: 63px;
  margin: 15px 8px 20px 10px;
  padding: 3px 13px 2px;
}

.AppointmentBadge .badges {
  display: flex;
  flex-direction: row;
}

.AppointmentBadge .badges a {
  width: 50%;
}
.AppointmentBadge .badges img {
  width: 122px;
  height: 38px;
}

.NoData {
  font-size: 20px;
  text-align: center;
  margin: 150px 0;
}

.inputFieldDisabled input {
  background-color: #e8e8e8 !important;
}

.summarySublotLabel {
  display: inline-block;
  background-color: orange;
  padding: 0 4px;
  font-size: 12px;
  border-radius: 5px;
}

.pickReasonsHeading {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px 0px 10px;
  font-weight: bold;
}

.helpText {
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: #171717;
}

.SMSSuccessHelpText {
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: #7dc033;
}

.appointmentSummaryPopupHeader {
  font-size: 16px;
  display: flex;
  padding: 10px 20px 0 20px;
  font-weight: bold;
  height: auto;
  align-items: center;
  justify-content: space-between;
}

.ui.error.message.errorMessage {
  margin-left: 20px;
  margin-right: 20px;
}

.joinOfficeQueueBlock {
  margin-top: 10px;
}

.Placeholder .lastUpdateTime {
  width: 50%;
  margin-left: auto;
}

.Placeholder .summaryHeader {
  width: 50%;
  margin: 15px auto 10px;
}

.Placeholder .date {
  width: 50%;
  margin: 0 auto 10px;
}

.Placeholder .time {
  width: 30%;
  margin: 0 auto 10px;
}

.Placeholder .weAreReadyText {
  width: 85%;
  margin: 0 auto 5px;
}

.Placeholder .weAreReadySubText {
  margin: 0 auto 5px;
  width: 70%;
  margin-bottom: 15px;
}

.Placeholder .weAreReadyText .ui.placeholder,
.Placeholder .weAreReadySubText .ui.placeholder,
.Placeholder .summaryHeader .ui.placeholder,
.Placeholder .date .ui.placeholder,
.Placeholder .time .ui.placeholder {
  height: 25px;
}

.Placeholder .cancelAppointment {
  width: 50%;
  margin: 10px auto;
}
